import React, { useState } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/seo"
import Layout from "../components/Layout"
import InnovationExplorer from "../components/InnovationExplorer"
import PageIntro from "../components/PageIntro"
import ReactMarkdown from "react-markdown"

function getSchemaHeight() {
  if (typeof window !== "undefined") {
    if (window.matchMedia("(max-width: 400px)").matches) {
      return 250
    }
    if (window.matchMedia("(max-width: 1050px)").matches) {
      return 350
    }
  }
  return 500
}

export const HowToActPageTemplate = ({ title, heading, boosts }) => {
  const boostList = ["engagement", "capacity", "vision"]
  const [activeBoost, setActiveBoost] = useState("engagement")
  const height = getSchemaHeight()

  const radius = height / 2
  const parts = 3

  const partAngle = 360 / parts
  const partIsoceleAngle = (180 - partAngle) / 2

  const angleTan = Math.tan((partIsoceleAngle * Math.PI) / 180)
  const partWidth = (2 * radius) / angleTan

  return (
    <HowToActWrapper
      activeBoost={activeBoost}
      height={height}
      partWidth={partWidth}
      partAngle={partAngle}
    >
      <div className="content container">
        <h1>{title}</h1>
        <div className="columns">
          <div className="column column-6">
            <h2 className="subheading">{heading}</h2>
            <div className="large">
              <ReactMarkdown source={boosts[activeBoost]} />
            </div>
          </div>
          <div className="column column-6">
            {/* <div className="imgContainer"></div> */}
            <div class="wrapper">
              <div></div>
              <div></div>
              <div>
                <div onClick={() => setActiveBoost("engagement")}>
                  <span className="boost-title">Engagement</span>
                  <span>Resident engagement</span>
                  <span>External partnership</span>
                  <span>Horizontal collaboration</span>
                </div>
                <div onClick={() => setActiveBoost("capacity")}>
                  <span className="boost-title">Capacity</span>
                  <span>Evaluation</span>
                  <span>Funding</span>
                  <span>Staff skills</span>
                </div>
                <div onClick={() => setActiveBoost("vision")}>
                  <span className="boost-title">Vision</span>
                  <span>Leadership</span>
                  <span>Culture</span>
                  <span>Strategy</span>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button
                onClick={() => {
                  setActiveBoost(
                    old => boostList[(boostList.indexOf(old) + 3 - 1) % 3]
                  )
                }}
              >
                &lt;
              </button>
              <button
                onClick={() => {
                  setActiveBoost(
                    old => boostList[(boostList.indexOf(old) + 1) % 3]
                  )
                }}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </div>
    </HowToActWrapper>
  )
}
const HowToActWrapper = styled.div`
  padding: 3rem 0;
  background: #2c97a1;

  h1, h2, h4 {
    font-family: ${({ theme }) => theme.fonts["roboto"]};
  }
  h3, p {
    font-family: ${({ theme }) => theme.fonts["vollkorn"]};
  }
  h1 {
    color: rgb(25, 45, 111);
    ${({ theme }) => theme.isHeadingSize["3"]};
    margin-bottom: 1.875rem;
  }
  h2 {
    /* margin-bottom: 1.5rem; */
    ${({ theme }) => theme.isHeadingSize["1"]};
  }

  h3 {
    margin-bottom: 1.5rem;
    /* font-size: 1.8rem; */
  }

  h4 {
    /* font-size: 1.25rem; */
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1.2rem;
    line-height: 1.4 !important;
  }

  h2, h3, h4, p {
    color: white;
  }

  .buttons {
    margin: 4rem auto 0;
    text-align: right;
    button {
      background-color: transparent;
      border: none;
      color: white;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding: 4px 8px;
      transition: all ease 0.3s;
      margin: 0;
      &:hover {
        cursor: pointer;
        color: rgb(25, 45, 111);
        background-color: #f8f8f8;
      }
      &:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-left: 1px solid white;
      }
      &:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border-right: 1px solid white;
      }
    }
  }

  .wrapper {
    margin: auto;
    position: relative;
    display: block;
    width: ${props => props.height}px;
    height: ${props => props.height}px;
    & > div:nth-child(1) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(${props => props.height}px / 3);
      height: calc(${props => props.height}px / 3);
      border-radius: 50%;
      background-color: white;
      z-index: 3;
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(${props => props.height}px * 2 / 3);
      height: calc(${props => props.height}px * 2 / 3);
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 2;
      pointer-events: none;
    }

    & > div:nth-child(3) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props => props.height}px;
      height: ${props => props.height}px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      z-index: 1;
      overflow: hidden;
      & > div {
        all: unset;
        position: absolute;
        bottom: 50%;
        right: 50%;
        width: ${props => props.partWidth}px;
        height: calc(${props => props.height}px / 2);
        transform-origin: center bottom;
        clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
        border: 1px solid rgb(255, 255, 255);
        transition: background-color ease 0.23s;
        color: white;
        text-align: center;
        .boost-title {
          display: inline-block;
          position: relative;
          top: calc(50% - 0.5em);
          /* top: ${props => props.height / 6 + props.height / 12}px; */
          font-size: ${props => props.height / 32}px;
        }
        span {
          font-family: ${({ theme }) => theme.fonts["roboto"]};
          font-weight: 600;
          &:not(:first-child) {
            position: absolute;
            max-width: ${props => props.height / 6 - 10}px;
            text-align: center;
            display: inline-block;
            transform-origin: center center;
            transform: rotate(calc(-${props => props.partAngle}deg + 60deg));
            font-size: ${props => props.height / 48}px;
          }
          &:nth-child(2) {
            top: calc(50% - ${props => props.height}px / 12);
            left: calc(50% - ${props => props.height}px / 3);
            transform: translateY(-50%) rotate(calc(-${props =>
              props.partAngle}deg + 60deg));
          }
          &:nth-child(3) {
            top: calc(${props => props.height / 16}px);
            left: 50%;
            transform: translateX(-50%)
              rotate(calc(-${props => props.partAngle}deg + 60deg));
          }
          &:nth-child(4) {
            top: calc(50% - ${props => props.height}px / 12);
            right: calc(50% - ${props => props.height}px / 3);
          }
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
      }

      & > div:nth-child(1) {
        transform: translateX(50%) rotate(60deg);
        ${props =>
          props.activeBoost == "engagement" &&
          css`
            background: rgb(25, 45, 111);
          `}
      }

      & > div:nth-child(2) {
        transform: translateX(50%)
          rotate(calc(${props => props.partAngle}deg + 60deg));
        ${props =>
          props.activeBoost == "capacity" &&
          css`
            background: rgb(25, 45, 111);
          `}
        .boost-title {
          transform: rotate(180deg);
        }
        span {
          &:not(:first-child) {
            transform: rotate(180deg);
          }
          &:nth-child(3) {
            top: calc(${props => props.height / 16}px);
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
          }
        }
      }

      & > div:nth-child(3) {
        transform: translateX(50%)
          rotate(calc((${props => props.partAngle}deg * 2) + 60deg));
        ${props =>
          props.activeBoost == "vision" &&
          css`
            background: rgb(25, 45, 111);
          `}

        span {
          &:not(:first-child) {
            transform: rotate(calc(${props => props.partAngle}deg / 2));
          }
          &:nth-child(3) {
            top: calc(${props => props.height / 16}px);
            left: 50%;
            transform: translateX(-50%)
              rotate(calc(${props => props.partAngle}deg / 2));
          }
          &:nth-child(4) {
            transform: translateY(100%) rotate(calc(${props =>
              props.partAngle}deg / 2));
          }
        }
      }
    }
  }
`

const HowToAct = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  return (
    <Layout>
      <SEO title={"How to act"} />
      <HowToActPageTemplate {...frontmatter}></HowToActPageTemplate>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "how-to-act-page" } }) {
      frontmatter {
        title
        heading
        boosts {
          vision
          engagement
          capacity
        }
      }
    }
  }
`

export default HowToAct
